<template>
  <b-row no-gutters class="mt-4">
    <b-row class="mt-3 w-100">
      <b-col class="gray-col-title" cols="3">
        <d-radio-button
            :text="$t('views.top-bar.settings.working-conditions.cancellation-condition.none.title')"
            :row="{ singleSelection: selection.none }"
            @on:radio-button-check="onCheck('none')"
        />
      </b-col>
      <b-col cols="9" class="black-col-title">
        {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.none.description') }}
      </b-col>
    </b-row>
    <b-row class="mt-3 w-100">
      <b-col class="gray-col-title" cols="3">
        <d-radio-button
            :text="$t('views.top-bar.settings.working-conditions.cancellation-condition.flexible.title')"
            :row="{ singleSelection: selection.flexible }"
            @on:radio-button-check="onCheck('soft')"
        />
      </b-col>
      <b-col cols="9" class="black-col-title">
        {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.flexible.description') }}
      </b-col>
    </b-row>
    <b-row class="mt-3 w-100">
      <b-col class="gray-col-title" cols="3">
        <d-radio-button
            :text="$t('views.top-bar.settings.working-conditions.cancellation-condition.strict.title')"
            :row="{ singleSelection: selection.strict }"
            @on:radio-button-check="onCheck('strict')"
        />
      </b-col>
      <b-col cols="9" class="black-col-title">
        {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.strict.description') }}
      </b-col>
    </b-row>
    <b-row class="mt-3 w-100">
      <b-col class="gray-col-title" cols="3">
        <d-radio-button
            :text="$t('views.top-bar.settings.working-conditions.cancellation-condition.custom.title')"
            :row="{ singleSelection: selection.custom }"
            @on:radio-button-check="onCheck('custom')"
        />
      </b-col>
      <b-col cols="9" class="d-flex black-col-title">
        {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.custom.description-start') }}
        <b-form-input style="width: 8%" size="sm" class="h-75  text-center background-light-blue-inputs ml-1 mr-1"></b-form-input>
        {{ $t('views.top-bar.settings.working-conditions.cancellation-condition.custom.description-end') }}
      </b-col>
      <b-col v-if="!selection.custom" cols="3"/>
      <b-col v-if="!selection.custom" cols="9">
        <b-form-invalid-feedback
            :state="selection.custom"
        >
          {{ $t('validation.required') }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
  </b-row>
</template>
<script>
  import {putClub} from "@api/doinsport/services/club/club.api";

  export default {
    props: {
      club: {
        type: Object,
        default: () => {},
      }
    },
    data: () => ({
      selection: {
        flexible: true,
        none: true,
        strict: true,
        custom: true,
      }
    }),
    methods: {
      onCheck(scope) {
        this.initCondition(scope);
      },
      initCondition(condition) {
        switch (condition) {
          case 'strict':
            this.selection.strict = false;
            this.selection.custom = true;
            this.selection.flexible = true;
            this.selection.none = true;
            putClub(this.club.id, {bookingCancellationConditionType: condition});
            break;
          case 'custom':
            this.selection.strict = true;
            this.selection.custom = false;
            this.selection.flexible = true;
            this.selection.none = true;
            putClub(this.club.id, {bookingCancellationConditionType: condition});
            break
          case 'soft':
            this.selection.strict = true;
            this.selection.custom = true;
            this.selection.flexible = false;
            this.selection.none = true;
            putClub(this.club.id, {bookingCancellationConditionType: condition});
            break;
          default:
            this.selection.strict = true;
            this.selection.custom = true;
            this.selection.flexible = true;
            this.selection.none = false;
            putClub(this.club.id, {bookingCancellationConditionType: condition});
        }
      }
    },
    created () {
      this.initCondition(this.club.bookingCancellationConditionType);
    }
  }
</script>
<style scoped>
/deep/ .con-vs-radio {
  justify-content: left;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
}
</style>
